/**
 * Imports
 */

// Remix imports
import { json } from '@remix-run/node';
import type {
  LinksFunction,
  LoaderFunction,
  MetaFunction } from
'@remix-run/node';
import {
  Links,
  Link,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData } from
'@remix-run/react';
import { DynamicLinks } from 'remix-utils';
import type { DynamicLinksFunction } from 'remix-utils';

// ~/images imports
import * as images from '~/images';

// ~/models imports
import { getTenant } from './models/tenant.server';
import type { Tenant } from './models/tenant.server';

// ~/styles imports
import styles from './styles/app.css';
import flatpickrStyle from 'flatpickr/dist/themes/airbnb.css';

// Sentry import
import { withSentry, captureException } from '@sentry/remix';

/**
 * unstable_shouldReload
 * This function lets apps optimize which routes should be reloaded on some client-side transitions.
 */
export function unstable_shouldReload({ submission }: {submission: any;}) {
  return submission?.formData?.get('unstable_shouldReload') !== 'false';
}

/**
 * Remix loader
 */
type LoaderData = {
  title: string;
  favicon: string;
};
export const loader: LoaderFunction = async ({ request }) => {
  const tenant: Tenant = getTenant(request);

  //
  let _favicon = tenant.favicon || images.bankablefintech_favicon;
  let _title = tenant.name || 'Bankable Fintech';

  //
  return json<LoaderData>({ favicon: _favicon, title: _title });
};

/**
 * Meta function
 */
export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  viewport: 'width=device-width,initial-scale=1',
  meta: [
  {
    property: 'og:logo',
    content: images.bankablefintech_favicon
  }]

});

/**
 * Links functions
 */
export const links: LinksFunction = () => {
  let links = [
  { rel: 'stylesheet', href: styles },
  {
    rel: 'stylesheet',
    href: flatpickrStyle
  }];

  return links;
};
export const dynamicLinks: DynamicLinksFunction<LoaderData> = ({ data }) => {
  let links = [
  {
    rel: 'icon',
    href: data.favicon,
    type: 'image/png'
  }];

  return links;
};

/**
 * Remix error boundary
 */
export function ErrorBoundary({ error }: {error: any;}) {
  const color = 'gray';

  console.error(error);
  captureException(error);
  return (
    <html>
      <head>
        <title>Oh no!</title>
      </head>
      <body>
        <div className="mx-auto flex w-full max-w-7xl flex-grow flex-col px-4 sm:px-6 lg:px-8">
          <div className="my-auto flex-shrink-0">
            <h3 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-xl">
              Something went wrong
            </h3>
            <p className="mt-2 text-base text-gray-500">
              Oops! We're experiencing technical difficulties. Please retry your
              request. If the issue persists, please contact
              support@bankabledomain.com.
            </p>
            <div className="mt-6">
              <Link
                to={''}
                className={`text-base font-medium text-${color}-600 hover:text-${color}-500 flex`}>

                {/* <RefreshIcon className={`w-4 h-4 mr-2 mt-2`}/> */}
                Refresh
              </Link>
            </div>
          </div>
        </div>
      </body>
    </html>);

}

/**
 * Include custom handles
 */
export const handle = { dynamicLinks };

/**
 * Google Tag Manager scripts
 */
export const googleTagManagerScript: string = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-NQTWDZP');`;
export const googleTagManagerNoScript = () => {
  return (
    <>
      {/* <!-- Google Tag Manager (noscript) --> */}
      <noscript>
        <iframe
          title="Google Tag Manager (noscript)"
          src="https://www.googletagmanager.com/ns.html?id=GTM-NQTWDZP"
          height="0"
          width="0"
          style={{ display: 'none', visibility: 'hidden' }}>
        </iframe>
      </noscript>
      {/* <!-- End Google Tag Manager (noscript) --> */}
    </>);

};

/**
 * React component
 */
function App() {
  const { title } = useLoaderData();

  // Image build shim to load entire images directory.
  images;

  return (
    <html lang="en" className="h-full">
      <head>
        <script dangerouslySetInnerHTML={{ __html: googleTagManagerScript }} />
        <Meta />
        {title ? <title>{title}</title> : null}
        <DynamicLinks />
        <Links />
        {/* <script async type="text/javascript" src="/newrelic.js" /> */}
      </head>
      <body className="h-full">
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>);

}
export default withSentry(App, {
  wrapWithErrorBoundary: false
});